import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import {
  SERVER_RESPONSE_FAILED,
  ACTIVATION,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";
import { 
  track, 
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
} from 'utils/segmenTracker';

const initialState = {
  config: {},
};

export const configuration = {
  state: { ...initialState },
  reducers: {
    setConfig (state, payload) {
      return { ...state, config: { ...state.config, ...payload } };
    },
  },
  effects: dispatch => {
    return {
      async getConfiguration ({ module, id }, state) {
        dispatch.apiStatus.setProcessing(true);
  
        fetchApi({
          method: 'get',
          url: svAPI.getConfigParams.replace(':module', module).replace(':id', id),
        })
          .then(response => {
            const { data : { data: { attributes } } } = response;
            dispatch.configuration.setConfig({ ...attributes?.configuration });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'GET Config Params', svAPI.getConfigParams, 'STRAPI API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'STRAPI API')})
          });
      },
    };
  },
};
