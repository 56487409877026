import fetchApi from "../../services/fetch";
import { svAPI } from "../../constants/api";
import { pinEncryptKeyPair } from "../../config/rsa";
import { 
  track, 
  updateGlobalTracker,
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
 } from '../../utils/segmenTracker';
import { 
  PRODUCT_LIST_VIEWED,
  ACTIVATION,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";
import { filterPackagesByType } from "../../utils/utils";

const NodeRSA = require("node-rsa");
const key = new NodeRSA(pinEncryptKeyPair);

const initialState = {
  selectedAddons: [],
  packages: [],
  currentSelected: [],
  activationCost: {},
  monthsContract: "0 meses"
};

export const packages = {
  state: { ...initialState },
  reducers: {
    saveSelection(state, payload) {
      const currentSelection = state.currentSelected.find(
        selected => selected.planId === payload.planId
      );
      let filteredSelection;
      if (currentSelection) {
        filteredSelection = state.currentSelected.filter(
          selection => selection.planId !== payload.planId
        );
      } else {
        filteredSelection = [...state.currentSelected, payload];
      }

      return { ...state, currentSelected: filteredSelection };
    },
    updatePackageList(state, payload) {
      return { ...state, packages: payload };
    },
    removeSelected(state, payload) {
      return {
        ...state,
        selectedAddons: state.selectedAddons.filter(
          selected => selected.planId !== payload.planId
        ),
        currentSelected: state.currentSelected.filter(
          selected => selected.planId !== payload.planId
        )
      };
    },
    clearPackages() {
      return {
        ...initialState
      };
    },
    clearCart(state) {
      return {
        ...state,
        selectedAddons: initialState.selectedAddons,
        currentSelected: initialState.currentSelected,
        activationCost: initialState.activationCost,
        monthsContract: initialState.monthsContract
      };
    },
    updateActivationCost(state, payload) {
      return {
        ...state,
        activationCost: payload
      };
    },
    updateActivationMonth(state, payload) {
      return {
        ...state,
        monthsContract: payload
      };
    },
    addToCart(state, payload) {
      return {
        ...state,
        selectedAddons: payload
      }
    }
  },

  effects: dispatch => {
    return {
      async getPackages(data) {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "post",
          url: svAPI.products,
          data: data.body,
          params: data.params
        })
          .then(response => {
            dispatch.packages.updatePackageList(response.data);
           const packageList = filterPackagesByType(response.data, 'bundle').map( item => {
            return {
              product_id: item.planId,
              product_category: 'plan',
              product_name: item.name,
              product_variant: item.type, 
              product_price: item.priceList[0].value,
              product_quantity: ""
            };
           })
          const additionalData = {
            product_default: 'PAQUETE',
            products: packageList
          }
          data.callBack && data.callBack(response.data);
          if(localStorage.getItem('msisdn')) additionalData['msisdn'] = localStorage.getItem('msisdn')
          updateGlobalTracker({...additionalData})
          !data?.noTrack && track(PRODUCT_LIST_VIEWED);
            dispatch.apiStatus.setProcessing(false);
            data.callback && data.callback(response.data);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            data.callBack && data.callBack();
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Packages', svAPI.products, 'Packages API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Packages API')});
          });
      },
      async getActivationCost(data, state) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: "post",
          url: svAPI.activationCost,
          data: {
            PIN: key.encrypt(data.pin, "base64"),
            productType: data.productType,
            monthsContract: data.monthsContract,
            node: data.node,
            documentType: data?.documentType?.toUpperCase(),
            documentNumber: data.documentNumber,
            flow: state?.configuration?.config?.cxiFlow || 'O'
          }
        })
          .then(response => {
            dispatch.packages.updateActivationCost(response.data);
            dispatch.apiStatus.setProcessing(false);
            const res = response?.data || {};
            return Promise.resolve({ ...res, month: data.monthsContract, success: true,  })
          })
          .catch(error => {
            if (error.errorCode !== 404) {
              dispatch.apiStatus.setRequestFailed({ ...error });
            } else {
              dispatch.apiStatus.setProcessing(false);
            }
            return Promise.resolve({ error, month: data.monthsContract, success: false })
          });
      }
    };
  }
};
